import React, { useRef, useState } from 'react';

import { ApiPaths, METHODS_TYPES } from '../../../utils';
import { NumericFormat } from 'react-number-format';

export default function CampoServicios({ servicio, showButtons = true, handlerServicioUpdate, nombre, cuposDisponibles, cuposDisponiblesHospedaje, children, className }) {

    const serviceCounter = useRef(servicio.cantidad ? servicio.cantidad : 0)

    const handlerServicioClickCounter = (method) => {


        if (method === METHODS_TYPES.adicionar) {
            serviceCounter.current = serviceCounter.current + 1;
        } else if (method === METHODS_TYPES.remover) {
            serviceCounter.current = serviceCounter.current - 1
        }

        handlerServicioUpdate(servicio, method, serviceCounter.current)
    }

    return (
        <div className={`servicio-item ${className ? className : ''} `} >
            <div className='servicio-item_container'>
                <div className='servicio-image'>
                    {servicio.guia?.imagen ? <img width="70" height="70" src={ApiPaths.guias + servicio.guia.imagen} alt={`Imagen para el servicio ` + servicio.nombre} /> : <img width="70" height="70" src={ApiPaths.servicios + servicio.imagen} alt={`Imagen para el servicio` + servicio.nombre} />}
                </div>
                <div className='servicio-description'>
                    {!nombre ?
                        <>
                            <h4 className='nombre'>
                                {servicio.nombre}
                            </h4>
                            {servicio.descripcion !== "" ?
                                <p className='instrucciones'>{servicio.descripcion}</p> :
                                ""
                            }

                        </>
                        : <h4 className='nombre'>
                            {nombre}
                        </h4>

                    }
                    {servicio.tarifa > 0 && showButtons && <p className='precio'>
                        <NumericFormat value={servicio.tarifa} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </p>}
                </div>
            </div>
            {showButtons ?
                <div className='selector'>
                    <div className='selector-btn btn-reduce' style={{ flex: 1, justifyContent: 'center' }}>
                        <button type='button' onClick={() => handlerServicioClickCounter(METHODS_TYPES.remover)} className='botonContadorResumen' disabled={serviceCounter.current <= 0}>-</button>
                    </div>
                    <div className='selector-number'>
                        {serviceCounter.current}
                    </div>
                    <div className='selector-btn btn-plus' style={{ flex: 1 }}>
                        <button type='button' disabled={cuposDisponibles ? serviceCounter.current >= cuposDisponibles : cuposDisponiblesHospedaje ? cuposDisponiblesHospedaje : false} onClick={() => handlerServicioClickCounter(METHODS_TYPES.adicionar)} className='botonContadorResumen'>+</button>
                    </div>
                </div>
                : null}

            {children &&
                <>
                    {children}
                </>

            }
        </div>

    )
}