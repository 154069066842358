import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import ReservaContext from '../../context/ReservaContext';
import Visitantes from './Steps/Visitantes';
import Calendario from './Steps/Calendario';
import TransporteGuianza from './Steps/TransporteGuianza';
import AlimentacionHospedaje from './Steps/AlimentacionHospedaje';
import { InformacionPago } from './InformacionPago';
import { ApiPaths } from '../../utils';
import axios from 'axios';
import Stepper from '../componentes/stepper';
import { SUPERADMIN } from '../../constantes/roles';
import UserContext from '../../context/UserContext';
import moment from 'moment';

// Memoized Steps Components
const MemoVisitantes = React.memo(Visitantes);
const MemoCalendario = React.memo(Calendario);
const MemoTransporteGuianza = React.memo(TransporteGuianza);
const MemoAlimentacionHospedaje = React.memo(AlimentacionHospedaje);
const MemoInformacionPago = React.memo(InformacionPago);
const MemoStepper = React.memo(Stepper);

const EditarReservas = ({ id, modificacionesReserva }) => {
	const { step, serviciosTotal, cambiarServiciosTotal } = useContext(ReservaContext);
	const { tiposDeIngreso: tiposIngresos, user } = useContext(UserContext);

	const peticionTraeDatosCompletos = useCallback(async () => {
		try {
			const res = await axios(ApiPaths.reservas, { params: { completo: 'true', id, modificacionesReserva: modificacionesReserva === "true" ? modificacionesReserva : null } });
			const respuestaData = res.data;
			const fechas = respuestaData.fechaReservaFinal ? [
				new Date(respuestaData.fechaReservaInicial + "T00:00:00"),
				new Date(respuestaData.fechaReservaFinal + "T23:59:00")
			] : [
				new Date(respuestaData.fechaReservaInicial + "T00:00:00"),
				new Date(respuestaData.fechaReservaInicial + "T23:59:00")
			];

			const servicioGuia = JSON.parse(decodeURI(respuestaData.servicioGuia));

			const totalCantidadServicioGuia = servicioGuia ? new Set(
				servicioGuia
					.map(servicio => servicio.guia && servicio.guia.cedula) // Solo mapea si 'guia' existe
					.filter(cedula => cedula !== null && cedula !== undefined) // Filtra valores nulos y no definidos
			).size
				: 0;
			const entradasAntiguas = parseInt(respuestaData.entradas) + totalCantidadServicioGuia;

			const personas = JSON.parse(decodeURI(respuestaData.personas));
			const datosGet = {
				estado: respuestaData.estado,
				fechas,
				tipoIngreso: {
					id: respuestaData.tipoingresoId,
					nombre: respuestaData.tipoingreso,
					tipo: respuestaData.tipoingresoTipo,
					padre: respuestaData.tipoingresoPadre,
					capacidadlimite: respuestaData.tipoingresoCapacidadlimite,
					capacidadactual: respuestaData.tipoingresoCapacidadactual,
					alertas: respuestaData.tipoingresoAlertas,
					multipleDias: respuestaData.tipoIngresoMultiple,
					senderoMultiple: respuestaData.tipoIngresoSenderoMultiple,
				},
				personas: personas,
				fechaReservaInicial: respuestaData.fechaReservaInicial,
				fechaReservaFinal: respuestaData.fechaReservaFinal,
				seguroMedico: respuestaData.entradas,
				serviciosAlimentacion: parseInt(respuestaData.totalServicioAlimentacion) > 0 ? {
					serviciosAlimentacion: JSON.parse(decodeURI(respuestaData.serviciosAlimentacion)),
					contarServicios: JSON.parse(decodeURI(respuestaData.contarServicios)),
					totalServicioAlimentacion: parseInt(respuestaData.totalServicioAlimentacion),
					restriccionesAlimenticias: respuestaData?.restriccionesAlimenticias
				} : null,
				servicioVehiculos: JSON.parse(decodeURI(respuestaData.servicioVehiculo)),
				servicioGuia,
				serviciosHospedaje: parseInt(respuestaData.totalServiciosGenericos) > 0 ? JSON.parse(decodeURI(respuestaData.serviciosHospedaje)) : null,
				entradas: parseInt(respuestaData.entradas),
				totalEntradas: parseInt(respuestaData.totalEntradas),
				totalServicioVehiculo: parseInt(respuestaData.totalServicioVehiculo),
				totalServicioGuia: parseInt(respuestaData.totalServicioGuia),
				totalServiciosGenericos: parseInt(respuestaData.totalServiciosGenericos),
				totalSeguros: parseInt(respuestaData.totalSeguros),
				total: parseInt(respuestaData.total),
				id: respuestaData.id,
				transaccionesEstado: respuestaData.transaccionesEstado,
				tiposEntradas: {
					total: 0,
					contador: 0
				},
				botonHabilitado: true,
				totalAbonos: parseInt(respuestaData.totalAbonos),
				modificacionesReserva,
				codigoReserva: respuestaData.codigoReserva,
				senderosDias: respuestaData.senderosDias ? JSON.parse(decodeURI(respuestaData.senderosDias)) : null,
				operadorId: respuestaData.operadorId,
				totalPagado: respuestaData?.totalPagado ? parseInt(respuestaData.totalPagado) : 0,
				cuposDisponibles: parseInt(respuestaData.cuposDisponibles),
				entradasAntiguas
			};

			if (parseInt(respuestaData.totalServicioAlimentacion) > 0) {
				const dias = {};
				datosGet.serviciosAlimentacion?.serviciosAlimentacion?.map(alimentacion => {
					dias[alimentacion.dia] = alimentacion.dia;
				});
				const diasActuales = Object.values(dias);

				datosGet['serviciosAlimentacion']['dias'] = diasActuales;
			}

			const { data: resTarifas } = await axios.get(`${ApiPaths.tarifas}?ids=${datosGet.tipoIngreso?.padre ? datosGet.tipoIngreso.padre : datosGet.tipoIngreso?.id}`);

			resTarifas.forEach((tarifa, index) => {
				resTarifas[index]['contador'] = 0;
				personas?.forEach(per => {
					if (tarifa.id === per.idTipoEntrada) {
						resTarifas[index]['contador'] += 1;
						datosGet['tiposEntradas']['total'] += parseInt(tarifa.tarifa);
						datosGet['tiposEntradas']['contador'] += 1;
					}
				});
			});
			datosGet['tiposEntradas']['data'] = resTarifas;
			datosGet['tipoEntradas'] = resTarifas;

			cambiarServiciosTotal(datosGet);
		} catch (error) {
			console.log(error);
		}
	}, [id, modificacionesReserva, tiposIngresos, cambiarServiciosTotal]);

	useEffect(() => {
		peticionTraeDatosCompletos();
	}, [peticionTraeDatosCompletos]);

	const renderContent = useMemo(() => {
		if (Object.entries(serviciosTotal).length === 0) return <p> cargando...</p>;

		const stepperDataSuperadmin = [
			{ step: 1, nombre: 'Datos Reserva' },
			{ step: 2, nombre: 'Añade personas' },
			{ step: 3, nombre: 'Servicios Uton' },
			{ step: 4, nombre: 'Servicios Uton' },
			{ step: 5, nombre: 'Resumen Reserva' }
		];

		const stepperDataUser = [
			{ step: 1, nombre: 'Añade personas' },
			{ step: 2, nombre: 'Servicios Uton' },
			{ step: 3, nombre: 'Servicios Uton' },
			{ step: 4, nombre: 'Resumen Reserva' }
		];

		const stepperData = parseInt(user.roll) === SUPERADMIN ? stepperDataSuperadmin : stepperDataUser;

		const renderStep = () => {
			const components = [
				<MemoCalendario />,
				<MemoVisitantes />,
				<MemoTransporteGuianza />,
				<MemoAlimentacionHospedaje />,
				<MemoInformacionPago />
			];

			const startIndex = parseInt(user.roll) === SUPERADMIN ? 0 : 1;

			return components[step - 1 + startIndex] || null;
		};

		return (
			<>
				<MemoStepper data={stepperData} stepActual={step} />
				{renderStep()}
			</>
		);
	}, [serviciosTotal, user.roll, step]);

	return renderContent;
}

export default EditarReservas;
