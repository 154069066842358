import React, { useContext, useEffect, useState } from "react";
import CampoServicio from "./CampoServicio";

import { ApiPaths, METHODS_TYPES, TIPO_SERVICIOS } from "../../../utils";
import UserContext from "../../../context/UserContext";
import axios from 'axios';

export default function ServiciosVehiculos({ titulo, servicios, actualizarServiciosGeneralesTotal, serviciosTotal, errors, value, tipoServicioVehiculoValue, cambiarServiciosTotal }) {
  const { user } = useContext(UserContext)

  const [tipoServicioVehiculo, setTipoServicioVehiculo] = useState(tipoServicioVehiculoValue || "")
  const [serviciosVehiculoList, setServiciosVehiculoList] = useState(value || [])
  const validarZonaPadre = serviciosTotal.tipoIngreso.padre ? parseInt(serviciosTotal.tipoIngreso.padre) : parseInt(serviciosTotal.tipoIngreso.id);
  const [isInvalid, setIsInvalid] = useState(!value?.botonHabilitado)

  const handlerServicioUpdate = (servicio, method, cantidad) => {
    const idxElement = serviciosVehiculoList.findIndex(({ id }) => id === servicio.id);

    let element = [...serviciosVehiculoList];
    element[idxElement] = !element[idxElement]['extradata'] ? { ...servicio, extradata: [], cantidad } : { ...servicio, cantidad }

    if (METHODS_TYPES.adicionar === method) {
      element[idxElement]['extradata'].push({ placa: '', conductor: '', documento: '', seguro: '' })
    } else if (METHODS_TYPES.remover === method) {
      element[idxElement]['extradata'].pop();
    }

    setServiciosVehiculoList(element)
    const serviciosVehiculosActuales = element.filter((servi => servi.extradata && servi.extradata.length > 0))
    const costoTotal = serviciosVehiculosActuales.reduce((acumulador, actual) => acumulador + ((actual.cantidad ? actual.cantidad : 1) * parseInt(actual.tarifa)), 0);

    actualizarServiciosGeneralesTotal({ servicioVehiculo: element, totalServicioVehiculo: costoTotal, tipoServicioVehiculo })
  }

  const llenarDatosdeVehiculo = (nombre, valor, id, index) => {
    let array = []
    serviciosVehiculoList.map(item => {
      if (item.id == id) {
        let arryser = []
        item.extradata.map((itemm, itemmIndex) => {
          if (itemmIndex == index) {
            arryser.push({ ...itemm, [nombre]: itemm[nombre] ? valor : valor })
          } else { arryser.push(itemm) }
        })
        array.push({ ...item, extradata: arryser })
      } else { array.push(item) }
    })

    setServiciosVehiculoList(array)
    const serviciosVehiculosActuales = array.filter((servi => servi.extradata && servi.extradata.length > 0))
    const costoTotal = serviciosVehiculosActuales.reduce((acumulador, actual) => acumulador + ((actual.cantidad ? actual.cantidad : 1) * parseInt(actual.tarifa)), 0);
    actualizarServiciosGeneralesTotal({ servicioVehiculo: array, totalServicioVehiculo: costoTotal, tipoServicioVehiculo })

  }

  const SeleccionaTipoIngreso = (ev) => {
    cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: true })
    let tipoServicioSelected = ev.target.value;
    setTipoServicioVehiculo(tipoServicioSelected)
    let serviciosArr = servicios.filter(({ tipo }) => tipo === tipoServicioSelected);
    setServiciosVehiculoList(serviciosArr);
  }

  const validarCodigoReserva = async (codigoReserva, servicioId, index) => {
    try {
      const resp = await axios(ApiPaths.reservas, { params: { codigoReserva } });
      llenarDatosdeVehiculo('codReserva', codigoReserva, servicioId, index)
      setIsInvalid(resp.data.length > 0)
      cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: resp.data.length > 0 })
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <div className={`CampoServicios row ${errors.servicioVehiculos && 'is-invalid'}`}>
      <h4 className='col-12 HeaderTablaServicios'>{titulo} para ({serviciosTotal.entradas}) visitantes</h4>
      <div className='col-4 offset-8 ContSelectIngreso' style={{ marginBottom: 15 }}>
        <select
          className={"SelectIngreso"}
          value={tipoServicioVehiculo}
          onChange={SeleccionaTipoIngreso}
        >
          <option value={""}>¿En que Vehículo Ingresa?</option>
          <option value={TIPO_SERVICIOS.vehiculo}>Vehiculo Propio</option>
          {validarZonaPadre == 2 && <option value={TIPO_SERVICIOS.alquiler}>Ruta Circular</option>}
          <option value={TIPO_SERVICIOS.vehiculoCodigo}>Ingresa con el vehículo de otra reserva</option>

        </select>
      </div>
      {serviciosVehiculoList.map((servicio, index) =>
        <div className='col-4 px-2 py-4' key={servicio.id}>
          <CampoServicio servicio={servicio} handlerServicioUpdate={handlerServicioUpdate} cuposDisponibles={!user && servicio.id == "18" ? servicio.capacidad : null} />
          {servicio.tipo != "Alquiler" && servicio.tipo != TIPO_SERVICIOS.vehiculoCodigo && servicio.extradata && servicio.extradata.map((item, idx) => {
            return (<div style={{ borderTop: "1px solid black", marginTop: "10px", paddingTop: "5px" }} key={idx} >

              <input value={item.placa} required onChange={(e) => llenarDatosdeVehiculo('placa', e.target.value, servicio.id, idx)} className="form-control mt-2" type="text" placeholder="Placa" />
              <input value={item.conductor} required onChange={(e) => llenarDatosdeVehiculo('conductor', e.target.value, servicio.id, idx)} className="form-control mt-2" type="text" placeholder="Conductor" />
              <input value={item.documento} required onChange={(e) => llenarDatosdeVehiculo('documento', e.target.value, servicio.id, idx)} className="form-control mt-2" type="text" placeholder="No. documento" />
              <input value={item.seguro} required onChange={(e) => llenarDatosdeVehiculo('seguro', e.target.value, servicio.id, idx)} className="form-control mt-2" type="text" placeholder="Cod. Seguro" />
            </div >)
          })}
          {servicio.tipo === TIPO_SERVICIOS.vehiculoCodigo && servicio.extradata && servicio.extradata.map((item, idx) => {

            return (<div style={{ borderTop: "1px solid black", marginTop: "10px", paddingTop: "5px" }} key={idx} >
              <input defaultValue={item.codReserva} required onChange={(e) => validarCodigoReserva(e.target.value, servicio.id, idx)} className={`form-control mt-2 ${isInvalid ? 'is-valid' : 'is-invalid'}`} type="text" placeholder="Cod reserva" pattern={!isInvalid} />
            </div >)
          })}
        </div>
      )}
    </div>
  )
}